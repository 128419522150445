import React from 'react';
import axios from 'axios';
import { FaWhatsapp } from 'react-icons/fa';

import Cards from './Cards';
import tempData from './tempData.json'
import { Spinner, Card } from 'react-bootstrap'
import Notfound from '../notfound.svg'
import InternalServerError from '../503-error.svg'
import "./Track.css"
import Footer from './Footer';
import getLanguage from './Language';
import DelayParcel from './DelayParcel';

class Track extends React.Component {
    state = {
        orderArr : [],
        luckyNumbersArr : [],
        loading: false,
        internalServerError: false,
        daysFromNow: 0,
        courier: ""
    }
    
    async componentDidMount() {
        //CHECK LANGUAGE
        if(!this.props.lang) {
            this.props.showLanguageModal();
        }

        const orderId = this.props.match.params.id;
        const { route } = this.props;
        // Origin Test Power App
        const url = (id) => {
            switch (route) {
                case "track": return `https://sheet.best/api/sheets/166d111a-5945-4192-8e0a-d10b69cf61fb/Order%20ID/*${id}*`;
                case "waybill": return `https://sheet.best/api/sheets/166d111a-5945-4192-8e0a-d10b69cf61fb/Waybill/*${id}*`;
                default: return `https://sheet.best/api/sheets/166d111a-5945-4192-8e0a-d10b69cf61fb/Order%20ID/*${id}*`;
            }
        }
        const urlLuckyDraw = (orderId) => {
            return `https://sheet.best/api/sheets/fafcb251-4a0e-4ecf-9af1-869f82f81116/ORDER/${orderId}`;
        }
        const urlPsid = (psid) => {
            return `https://sheet.best/api/sheets/fafcb251-4a0e-4ecf-9af1-869f82f81116/PSID/*${psid}*`;
        }

        const getLast4Digits = order => {
            if(order.Contact.includes('ERROR')) {
                return "";
            } else if(order.Contact.includes(`""`)) {
                const hpString = order.Contact.split(`""`)[0];
                return hpString.substr(hpString.length - 4);
            } else if(order.Contact) {
                return order.Contact.substr(order.Contact.length - 4);
            } else {
                return "";
            }
        }

        const getContactNumber = order => {
            if(order.Contact.includes('ERROR')) {
                return "";
            } else if(order.Contact.includes(`""`)) {
                return order.Contact.split(`""`)[0];
            } else if(order.Contact) {
                return order.Contact;
            } else {
                return "";
            }
        }

        const getWaybillFromURL = (url, order) => {
            let tracking = "";
            let expressName = "";
            let waybill = "";

            if(url.startsWith('https://www.tracking') || url.startsWith('https://tracking')) {
                tracking = url.split('.my/')[1];
                expressName = tracking && tracking.split('/')[0];
                waybill = tracking && tracking.split('/')[1];
            } else if(url.startsWith("https://www.ninjavan.co")) {
                // NINJA VAN
                waybill = url.split("https://www.ninjavan.co/en-my/tracking?id=")[1];
            } else if(url.startsWith("https://www.aramex.com/")) {
                waybill = url.split("ShipmentNumber=")[1];
            } else if(url.startsWith("https://teleport.delivery/")) {
                waybill = url.split("tracking_number=")[1];
            } else {
                waybill = "";
            }

            if(waybill && waybill.startsWith('618')) {
                this.setState({ courier: "zto" })
                return `zto/${waybill}?last4Digits=${getLast4Digits(order)}`;
            } else if(waybill && waybill.startsWith('NVMY')) {
                this.setState({ courier: "ninjavan" })
                return `ninjavan/${waybill}`
            } else if(waybill && waybill.startsWith('DDLMM')) {
                this.setState({ courier: "ninjavan" })
                return `ninjavan/${waybill}`
            } else if(waybill && waybill.startsWith('24')) {
                this.setState({ courier: "bbtransport" })
                return `bbtransport/${waybill}?hpNumber=${getContactNumber(order)}`
            } else if(waybill && waybill.startsWith('60')) {
                this.setState({ courier: "best" })
                return `best/${waybill}`
            } else if(waybill && waybill.startsWith('34')) {
                this.setState({ courier: "aramex" })
                return `aramex/${waybill}`
            } else if(waybill && waybill.startsWith('TP')) {
                this.setState({ courier: "teleport" })
                return `teleport/${waybill}`
            } else {
                this.setState({ courier: "" })
                return `unknown/${waybill}`;
            }
        }

        const trackingUrl = (t, order) => {
            const url = 'https://bcrp4b6ty1.execute-api.ap-southeast-1.amazonaws.com/latest';
            // const url = 'http://localhost:8000';
            if(t.startsWith('http')) {
                return `${url}/${getWaybillFromURL(t, order)}`;
            } else if(t.startsWith('NVMY') || t.startsWith('DDLMM')) {
                this.setState({ courier: "ninjavan" })
                return `${url}/ninjavan/${t}`;
            } else if(t.startsWith('24')) {
                this.setState({ courier: "bbtransport" })
                return `${url}/bbtransport/${t}?hpNumber=${getContactNumber(order)}`;
            } else if(t.startsWith('618')) {
                this.setState({ courier: "zto" })
                return `${url}/zto/${t}?last4Digits=${getLast4Digits(order)}`;
            } else if(t.startsWith('14')) {
                this.setState({ courier: "lalamove" })
                return `${url}/lalamove/${t}`;
            } else if(t.startsWith('60')) {
                this.setState({ courier: "best" })
                return `${url}/best/${t}`;
            } else if(t.startsWith('34')) {
                this.setState({ courier: "aramex" })
                return `${url}/aramex/${t}`;
            } else if(t.startsWith('TP')) {
                this.setState({ courier: "teleport" })
                return `${url}/teleport/${t}`;
            } else {
                this.setState({ courier: "" })
                return `${url}/unknown/${t}`;
            }
        }

        const isWaybillValid = w => {
            if(w && w.startsWith("http")) {
                if(w.startsWith('https://www.ninjavan.co')) {
                    return true;
                } else if(w.startsWith('https://www.aramex.com/')) {
                    return true;
                } else if(w.startsWith('https://teleport.delivery')) {
                    return true;
                } else if(w.startsWith('https://www.tracking' || w.startsWith('https://tracking'))) {
                    if(w.endsWith("undefined") || w === "https://www.tracking.my/") {
                        return false;
                    } else {
                        return true;
                    }
                }
            } else if(w && w.startsWith("NVMY")) { //NINJAVAN
                return true;
            } else if(w && w.startsWith("DDLMM")) { //NINJAVAN
                return true;
            } else if(w && w.startsWith(618)) { //ZTO
                return true;
            } else if(w && w.startsWith(24)) { // BB COLD TRANSPORT
                return true;
            } else if(w && w.startsWith(14)) { // LALAMOVE
                return true;
            } else if(w && w.startsWith(60)) { // BEST
                return true;
            } else if(w && w.startsWith("34")) { //ARAMEX SG
                return true;
            } else if(w && w.startsWith("TP")) { //TELEPORT SG
                return true;
            } else {
                return false;
            }
        }

        this.setState({ loading: true });
        let results;
        if(orderId.length < 18) {
            results = { data: [] };
        } else {
            results = await axios.get(url(orderId));
        }
        
        // LUCKY DRAW START
        let luckyNumbersArr = [];
        const luckyDrawResults = await axios.get(urlLuckyDraw(orderId));
        if(luckyDrawResults.data.length !== 0) {
            const psid = luckyDrawResults.data[0]["PSID"].split('/').reverse()[0];
            const fullLuckyDrawResults = await axios.get(urlPsid(psid));   
            fullLuckyDrawResults.data.map(d => {
                luckyNumbersArr.push(d['LUCKY']);
            })     
        }
        this.setState({ luckyNumbersArr });
        // LUCKY DRAW END

        let newData = "";
        let newArray = [];
        if(results.data.length > 0) {
            results.data.reverse().map(async (o, i) => {
                if(isWaybillValid(o.Waybill)) {
                    try {
                        const { data } = await axios.get(trackingUrl(o.Waybill, o));
                        newData = { ...o, tracking: data}
                        newArray.push(newData);
                        this.setState({ orderArr: newArray, loading: false, daysFromNow: data.daysFromNow });
                    } catch (error) {
                        newArray.push(o);
                        this.setState({ orderArr: newArray, loading: false });
                        // this.setState({ orderArr: [], loading: false, internalServerError: true })
                        console.log(error.response);
                        console.log(error.request);
                    }
                } else {
                    newArray.push(o);
                    this.setState({ orderArr: newArray, loading: false });
                }
            })
        } else {
            this.setState({ orderArr: [], loading: false })
        }

        
        // let newData = "";
        // let newArray = [];
        // tempData.reverse().map(async (o, i) => {
        //     console.log(isWaybillValid(o.Waybill))
        //     if(isWaybillValid(o.Waybill)) {
        //         const { data } = await axios.get(trackingUrl(o.Waybill));
        //         newData = { ...tempData.reverse()[i], tracking: data}
        //         newArray.push(newData);
        //         this.setState({ orderArr: newArray, loading: false});
        //     } else {
        //         newArray.push(tempData.reverse()[i]);
        //         this.setState({ orderArr: newArray, loading: false});
        //     }
        // })
    }

    renderSpinner = () => {
        return <Card key={this.props.index} className="card-spinner__wrapper">
            <Card.Body className="card-spinner">
                <Spinner animation="border" />
            </Card.Body>
        </Card>
    }

    renderNoData = () => {
        if(this.state.internalServerError) {
            return <Card key={this.props.index} className="notfound">
                <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={InternalServerError} alt="503 Service Unavailable" style={{ maxWidth: "350px" }} />
                    <div>
                        <Card.Text className='notfound-text__chi' style={{color: 'black', fontWeight: "500", marginBottom: 0}}>{getLanguage(this.props.lang, "internal_server_error")}</Card.Text>
                    </div>
                </Card.Body>
            </Card>
        } else {
            return <Card key={this.props.index} className="notfound">
                <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={Notfound} alt="404 Not Found" style={{ maxWidth: "350px" }} />
                    <div>
                        <Card.Text className='notfound-text__chi' style={{color: 'black', fontWeight: "500", marginBottom: 0}}>{getLanguage(this.props.lang, "not_found_tracking")}</Card.Text>
                    </div>
                </Card.Body>
            </Card>
        }
    }

    renderDelayParcel = () => {
        if(this.state.daysFromNow) {
            return <DelayParcel lang={this.props.lang} days={this.state.daysFromNow} />
            // return <DelayParcel lang={this.props.lang} days={4} />
        } else {
            return "";
        }
    }
    
    
    render() {
        return (
        <div style={{ paddingBottom: '0.5rem' }}>
            { this.renderDelayParcel() }
            {/* { !this.state.loading && <Card style={{ marginTop: "1.5rem", marginBottom: "0.5rem", marginLeft: "1.50rem", marginRight: '1.50rem', padding: "0.2rem 1rem", boxShadow: "1px 1px 8px #c9ccd2", overflow: 'hidden' }}>
                    <Card.Body id="warning-delivery">
                        <div style={{ fontSize: "0.9rem"}}>
                            <div style={{ fontWeight: 600 }}>由于春节靠近，快递订单量较多，邮寄过程可能比平日缓慢 2-3天，您的货品预计将在7个工作日内抵达，感恩您的耐心等待！</div>
                            <div>Due to the explosive growth of orders during the Spring Festival, overall delivery process may be 2-3 days slower than usual. Your goods are expected to arrive within 7 working days. We appreciate your patience!</div>
                        </div>
                    </Card.Body>
                </Card>
            } */}
            {
                this.state.loading ? (()=>{return <div></div>})() : this.state.luckyNumbersArr.length > 0 ? (()=>{
                    return <Card className='card-wrapper' id="lucky-wrapper" >
                            <Card.Body id="lucky-orange">
                                <div style={{ fontSize: "0.9rem", marginLeft: '10px'}}>
                                    <div><b><u>{this.state.luckyNumbersArr.length}</u>  幸运号码 Lucky number</b>: {this.state.luckyNumbersArr.join(", ")}</div>
                                </div>
                            </Card.Body>
                        </Card>
                })() : (()=>{return <div></div>})()
            }
            {
                this.state.loading ? this.renderSpinner() : this.state.orderArr.length > 0 ? this.state.orderArr.map((o, i) => {
                    return <Cards lang={this.props.lang} setLang={this.props.setLang} data={o} index={i} history={this.props.history} /> 
                }) : this.renderNoData()
            }
            { !this.state.loading && (this.state.courier === "zto" || this.state.courier === "best" ) && <Card className='card-info__wrapper'>
                    <Card.Body id="heeloo">
                        <div>
                            <div>{getLanguage(this.props.lang, "complaints")}</div>
                            <div><FaWhatsapp className='card-info__icon' /><b>016-5555080 / 010-2869677</b></div>
                        </div>
                    </Card.Body>
                </Card>}
            <Footer />
        </div>
        )
    }
}

export default Track;